import {
  AfterViewInit,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  OnDestroy,
  Renderer2,
  signal,
  viewChild,
} from "@angular/core"
import { TimelineService } from "./timeline.service"
import { TimelineItemsComponent } from "./items/timeline-items.component"
import { ProfileService } from "../../profile/profile.service"
import { Classification, Content, ContentType } from "../../content/content.model"
import { faCirclePlus, faFlag, faPencil, faSearchMinus, faSearchPlus, faTag } from "@fortawesome/pro-solid-svg-icons"
import { fromEvent, map, repeat, Subscription, switchMap } from "rxjs"
import { toObservable } from "@angular/core/rxjs-interop"
import { filter, takeUntil, tap } from "rxjs/operators"
import { PageToolbarService } from "../../page-toolbar.service"
import { UiIconComponent } from "ui/icon"
import { UiInheritDirective } from "ui/inherit"
import { EditorComponent } from "../../editor/editor.component"
import { FirestoreReadService } from "../../content/firestore-read.service"
import { EditorService } from "../../editor/services/editor.service"
import { HelpTipsComponent } from "feature/redline/help-tips"
import { NextSectionButtonComponent } from "@shared"
import { RouterLink } from "@angular/router"

@Component({
  imports: [
    TimelineItemsComponent,
    UiInheritDirective,
    UiIconComponent,
    EditorComponent,
    HelpTipsComponent,
    NextSectionButtonComponent,
    RouterLink,
  ],
  providers: [],
  standalone: true,
  template: `
    @if (!visited()) {
      <lib-feature-help-tips (close)="visitConfirmed()"/>
    }

    @if (showNewContentEditor() && newContent(); as newContent) {
      <e2e-editor
        context="admin"
        [content]="newContent"
        [isNewContent]="true"
        (close)="showNewContentEditor.set(false)"
        inherit
      />
    }

    <div
      #pageToolbarPlaceholder
      style="width: 100%"
      [style.height.px]="pageToolbarHeight()"
    ></div>
    
    <div
      class="text-base-content"
      [style.background-size]="background() ? 'cover' : ''"
      [style.height.px]="pageHeight"
      [style.width.px]="pageWidth"
    >
      <div
        #containerElement
        class="bg-base-100"
        style="height: 100%"
        [style.transform]="transformStyle()"
        [style.transform-origin]=" originX() + '0px ' + originY() + '0px'"
      >
        <div
          #backgroundLayer
          class="bg-primary absolute left-0"
          [style.top.px]="690"
          [style.height.px]="180"
          [style.width.px]="pageWidth"
        ></div>
        <e2e-timeline-items inherit/>
      </div>


      <div class="column fixed bottom-16 right-5">
        <div class="row rounded-full px-3 text-primary-content bg-primary">
          @if (isEditor()) {
            <button
              class="btn btn-ghost text-2xl"
              [class]="bookmarksDemo() ? 'outline outline-2' : ''"
              (click)="toggleBookmarksDemo()"
            >
              <lib-ui-icon [icon]="faTag"/>
            </button>
            <button
              class="btn btn-ghost text-2xl"
              (click)="createNewContent()"
            >
              <lib-ui-icon [icon]="faCirclePlus"/>
            </button>
            <button
              class="btn btn-ghost text-2xl"
              [class]="interactiveMode() ? '' : 'outline outline-2'"
              (click)="toggleInteractiveMode()"
            >
              <lib-ui-icon [icon]="faPencil"/>
            </button>
          }
          <button
            class="btn btn-ghost text-2xl"
            [disabled]="scale() === 1"
            (click)="zoomOut(1)"
          >
            <lib-ui-icon [icon]="faSearchMinus"/>
          </button>
          <button
            class="btn btn-ghost text-2xl"
            [disabled]="scale() === 5"
            (click)="zoomIn(scale() + 1)"
          >
            <lib-ui-icon [icon]="faSearchPlus"/>
          </button>
        </div>
      </div>

    </div>
  `,
  selector: "e2e-timeline",
})
export class TimelineComponent implements AfterViewInit, OnDestroy {
  private renderer = inject(Renderer2)
  private timelineService = inject(TimelineService)
  private profileService = inject(ProfileService)
  private pageToolbarService = inject(PageToolbarService)
  private editorService = inject(EditorService)
  private firestoreReadService = inject(FirestoreReadService)

  private containerElementRef = viewChild.required<ElementRef<HTMLElement>>("containerElement")

  pageToolbarHeight = this.pageToolbarService.pageToolbarHeight
  scale = this.timelineService.scale
  interactiveMode = this.timelineService.interactiveMode
  bookmarksButtonVariant = computed(() => {
    return this.timelineService.bookmarksDemo() ? "redlineBookmarkRed" : "redlineBookmarkGreen"
  })
  showNewContentEditor = signal(false)
  newContent = signal<Content | undefined>(undefined)
  bookmarksDemo = this.timelineService.bookmarksDemo
  background = this.timelineService.background
  isEditor = this.profileService.isEditor
  transformStyle = computed(() => "scale(" + this.timelineService.scale() + ")")
  pageHeight = this.timelineService.pageHeight
  pageWidth = this.timelineService.pageWidth

  originX = signal(0)
  originY = signal(0)
  yellowBackgroundImage = "url('assets/images/small with yellow 93kb.jpg')"

  visited = signal(false)

  dragEventSubscription: Subscription | undefined = undefined
  dragEvent$ = toObservable<ElementRef<HTMLElement>>(this.containerElementRef).pipe(
    map((elementRef) => elementRef.nativeElement),
    filter((nativeElement) => Boolean(nativeElement)),
    switchMap((nativeElement) => {
      const mouseDown$ = fromEvent<MouseEvent>(nativeElement, "mousedown")
      const mouseMove$ = fromEvent<MouseEvent>(nativeElement, "mousemove")
      const mouseUp$ = fromEvent<MouseEvent>(nativeElement, "mouseup")
      return mouseDown$.pipe(
        tap(() => this.timelineService.dragging.set(false)),
        map((event) => ({ x: event.clientX, y: event.clientY })),
        switchMap((startPos) =>
          mouseMove$.pipe(
            filter((event: MouseEvent) => {
              const dx = startPos.x - event.clientX
              const dy = startPos.y - event.clientY
              const distanceMoved = Math.sqrt(dx * dx + dy * dy)
              return this.timelineService.dragging() || distanceMoved > 50 // Change the minimum pixels dragged here
            }),
            tap(() => this.timelineService.dragging.set(true)),
            tap((event: MouseEvent) => {
              const scrollX = startPos.x - event.clientX
              const scrollY = startPos.y - event.clientY
              window.scrollBy(scrollX, scrollY)
              startPos.x = event.clientX
              startPos.y = event.clientY
            }),
          ),
        ),
        takeUntil(mouseUp$),
        repeat(),
      )
    }),
  )

  constructor() {
    effect(() => this.pageToolbarHeight()) // required in order to trigger template refresh when pageToolbarHeight changes
    this.visited.set(!!localStorage.getItem("visitedTimeline"))
    this.dragEventSubscription = this.dragEvent$.subscribe()
  }

  visitConfirmed() {
    localStorage.setItem("visitedTimeline", "true")
    this.visited.set(true)
  }

  ngAfterViewInit(): void {
    this.resetOrigin()
  }

  ngOnDestroy() {
    this.dragEventSubscription?.unsubscribe()
  }

  // @HostListener("window:scroll")
  // onScrollEvent(): void {
  // console.log("scroll")
  // console.log(window.pageXOffset)
  // console.log(window.pageYOffset)
  // this.divCurtain.nativeElement.style.top = window.pageYOffset.toString().concat('px');
  // }

  /**
   * The strategy is to use animation to set origin on target and zoom in,
   * then without animation reset origin to "0 0" and scroll to target.
   *
   * This keeps target in view without moving it, and allows scrolling up and left after zooming in.
   * We may be able to modulate origin during zoom-in to create a flying-in effect.
   */

  createTimelineItem() {
    /**
     * prompt for regional or national
     */
    // this.timelineService.createTimeline(Classification.REGIONAL)
    // this.editorService.createTimelineContent(Classification.REGIONAL)
  }

  createNewContent() {
    const user = {
      id: this.profileService.userId(),
      name: this.profileService.userName(),
      role: this.profileService.userRole(),
      region: this.profileService.region(),
    }
    const newContent = this.firestoreReadService
      .newContent(
        user,
        [ContentType.TIMELINE],
        Classification.REGIONAL,
      )
    this.newContent.set(newContent)
    this.showNewContentEditor.set(true)
  }

  zoomOut(scale: number): void {
    if (scale >= 1) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
      this.renderer.setStyle(this.containerElementRef().nativeElement, "transition", "1s ease-in-out")
      // this.store.dispatch(AppStore.TimelineActions.setScale({ scale }))
      this.timelineService.setScale(scale)
    }
  }

  zoomIn(scale: number): void {
    if (scale <= 5) {
      this.renderer.setStyle(this.containerElementRef().nativeElement, "transition", "1s ease-in-out")
      // this.store.dispatch(AppStore.TimelineActions.setScale({ scale }))
      this.timelineService.setScale(scale)
    }
  }

  /*
    resetScroll(): void {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  */

  resetOrigin(): void {
    if (this.containerElementRef().nativeElement) {
      this.renderer.setStyle(this.containerElementRef().nativeElement, "transition", "1s ease-in-out")
      this.originX.set(0)
      this.originY.set(0)
    }
  }

  /*
    changeOrigin(): void {
      this.renderer.setStyle(this.containerElementRef?.nativeElement, "transition", "1s ease-in-out")
      this.originX.set((1000 - 50) / 4)
      this.originY.set((1100 - 50) / 4)
    }
  */

  /*
    async resetOriginAndScroll(): Promise<void> {
      this.renderer.removeStyle(this.containerElementRef?.nativeElement, "transition")
      this.originX.set(0)
      this.originY.set(0)
      window.scrollTo({ top: 1100 - 50, left: 1000 - 50 })
    }
  */

  /*
    scrollTo(): void {
      window.scrollTo({ top: 1100 - 50, left: 1000 - 50, behavior: "smooth" })
    }
  */

  /*
    cdkDragEnded(event: CdkDragEnd, contentId: string): void {
      const position = this.stateService.state[contentId].form.get(["options", "position"]).value
      const newPosition = {
        left: {
          px: position.left.px + event.distance.x
        },
        top: {
          px: position.top.px + +event.distance.y
        }
      }

      newPosition.left.px = Math.max(0, newPosition.left.px) || 0
      newPosition.top.px = Math.max(0, newPosition.top.px) || 0
      newPosition.left.px = Math.min(2000 - 100, newPosition.left.px) || 0
      newPosition.top.px = Math.min(1000 - 100, newPosition.top.px) || 0

      this.stateService.state[contentId].form.get(["options", "position"]).setValue(newPosition)
      event.source._dragRef.reset()
      this.stateService.compareContentState(contentId)
    }
  */

  toggleBackgroundLayer(): void {
    this.background.update((boolean) => !boolean)
  }

  toggleBookmarksDemo() {
    this.timelineService.bookmarksDemo.update((boolean) => !boolean)
  }

  toggleInteractiveMode() {
    this.timelineService.setInteractiveMode(!this.timelineService.interactiveMode())
    // this.interactiveMode.update(boolean => !boolean)
  }

  protected readonly faSearchPlus = faSearchPlus
  protected readonly faSearchMinus = faSearchMinus
  protected readonly faFlag = faFlag
  protected readonly faPencil = faPencil
  protected readonly faCirclePlus = faCirclePlus
  protected readonly faTag = faTag
}
