import { Component, computed, inject } from "@angular/core"
import { RouterLink, RouterLinkActive } from "@angular/router"
import { ProfileService } from "./profile/profile.service"
import { RouteService } from "../services/route.service"
import { TourService } from "./tour/tour.service"
import { regionData } from "../regions/region.model"
import { PageMenuService } from "./page-menu.service"
import { UiDrawerComponent } from "ui/drawer"
import { FeatureSharedLoginService } from "feature/shared/login"

@Component({
  imports: [
    RouterLink,
    RouterLinkActive,
    UiDrawerComponent,
  ],
  standalone: true,
  template: `
    <lib-ui-drawer [show]="showMenu()" (close)="hidePageMenu()">
      <div class="pl-10 bg-base-200 text-base-content w-72" (click)="hidePageMenu()">
        <div class="border-l-8 border-dashed border-primary">
          <ul class="menu p-2">
            <li>  
              <a (click)="clickVirtualTour($event)">
                Virtual Tour (pre-alpha)
              </a>
            </li>
            <li>
              <a
                routerLink="/introduction"
                routerLinkActive="text-primary font-bold"
              >
                Introduction
              </a>
            </li>
            <li>
              <a
                routerLink="/redline-map"
                routerLinkActive="text-primary font-bold"
              >
                Redlining {{ regionLabel() ? "in " + regionLabel() : "Map" }}
              </a>
            </li>
            <li>
              <a
                routerLink="/timeline"
                routerLinkActive="text-primary font-bold"
              >
                Interactive Timeline
              </a>
            </li>
            <li>
              <a
                routerLink="/stories-map"
                routerLinkActive="text-primary font-bold"
              >
                Stories {{ regionLabel() ? "in " + regionLabel() : "from the Line" }}
              </a>
            </li>
<!--
            <li>
              <a
                routerLink="/undesign"
                routerLinkActive="text-primary font-bold"
              >
                How do we Undesign?
              </a>
            </li>
-->
            @if (loggedIn() === null) {
              <li>
                <a
                  [routerLink]="[]"
                  [queryParams]="queryParamsList().myAccountLogin"
                >
                  LOGIN
                </a>
              </li>
            }
            @if (loggedIn()) {
              <li>
                <a
                  href="logout"
                  (click)="logout($event)"
                >
                  LOG OUT
                </a>
              </li>
            }
            @if (isUserAdmin()) {
              <li>
                <a
                  routerLink="/users"
                  routerLinkActive="text-primary font-bold"
                >
                  Manage Users
                </a>
              </li>
            }
          </ul>
        </div>
      </div>
    </lib-ui-drawer>
  `,
  selector: "e2e-page-menu",
})
export class PageMenuComponent {
  private profileService = inject(ProfileService)
  private routeService = inject(RouteService)
  private tourService = inject(TourService)
  private pageMenuService = inject(PageMenuService)
  private featureSharedLoginService = inject(FeatureSharedLoginService)

  queryParamsList = this.routeService.queryParamsList

  regionLabel = computed(() => regionData[this.routeService.region()].label)
  loggedIn = this.profileService.loggedIn
  isUserAdmin = this.profileService.isUserAdmin

  showMenu = this.pageMenuService.showMenu

  hidePageMenu() {
    this.pageMenuService.unsetShowMenu()
  }

  setShowTour(showTour: boolean) {
    this.tourService.setShowTour(showTour)
  }

  clickVirtualTour(event: Event) {
    event.stopPropagation()
    this.setShowTour(true)
    this.hidePageMenu()
  }

  logout(event: Event) {
    event.preventDefault()
    this.featureSharedLoginService.signOut()
    this.hidePageMenu()
  }

}
