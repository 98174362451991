import { Component, computed, inject, input, signal } from "@angular/core"
import { Image, SectionEnum } from "../fields.type"
import {
  SvgGraphic,
  TimelineSvgGraphicsService,
} from "../../../pages/timeline/timeline.svg-graphics.service"
import { ImageCropperFormComponent } from "./image-cropper-form.component"
import { EditorService } from "../../../editor/services/editor.service"
import { SaveIconComponent } from "../../save-icon.component"
import { UiTextareaComponent } from "ui/textarea"
import { UiInheritDirective } from "ui/inherit"

@Component({
  imports: [
    ImageCropperFormComponent,
    SaveIconComponent,
    UiInheritDirective,
    UiTextareaComponent,
  ],
  standalone: true,
  template: `
    <div class="column" style="align-content: stretch">
      <div class="row">
        <lib-ui-textarea
          [rows]="1"
          placeholder="alt text - required"
          [value]="imageAlt()"
          (changes)="changeAlt($event)"
        />
        <e2e-save-icon [modelChange]="imageAltModel()"/>
      </div>
      <e2e-image-cropper-form [rowId]="rowId()"/>
    </div>
  `,
  selector: "e2e-image-edit",
})
export class ImageEditComponent {
  private editorService = inject(EditorService)
  private svgGraphicsService = inject(TimelineSvgGraphicsService)

  rowId = input.required<string>()

  imageAlt = computed(() => (this.editorService.editorContent()?.rows[this.rowId()] as Image)?.alt || "")

  svgGraphics: SvgGraphic[] = this.svgGraphicsService.svgGraphics.map((svgGraphic) => ({
    ...svgGraphic,
    fileName: svgGraphic.fileName,
  }))
  imageAltModel = signal<number | undefined>(undefined)

  changeAlt(alt: string) {
    this.imageAltModel.set(Date.now())
    const content = this.editorService.editorContent()
    if (content) {
      this.editorService.queueEditor({
        ...content,
        rows: {
          ...content.rows,
          [this.rowId()]: {
            ...content.rows[this.rowId()],
            alt,
            section: SectionEnum.IMAGE,
          },
        },
      })
    }
  }
}
