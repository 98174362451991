import { LngLat } from "../components/content/content.model"


export enum RegionEnum {
  BIRMINGHAM_AL = "BIRMINGHAM_AL",
  MOBILE_AL = "MOBILE_AL",
  MONTGOMERY_AL = "MONTGOMERY_AL",
  LITTLEROCK_AR = "LITTLEROCK_AR",
  PHOENIX_AZ = "PHOENIX_AZ",
  FRESNO_CA = "FRESNO_CA",
  LOSANGELES_CA = "LOSANGELES_CA",
  OAKLAND_CA = "OAKLAND_CA",
  SACRAMENTO_CA = "SACRAMENTO_CA",
  SANDIEGO_CA = "SANDIEGO_CA",
  SANFRANCISCO_CA = "SANFRANCISCO_CA",
  SANJOSE_CA = "SANJOSE_CA",
  STOCKTON_CA = "STOCKTON_CA",
  DENVER_CO = "DENVER_CO",
  PUEBLO_CO = "PUEBLO_CO",
  BRIDGEPORTANDFAIRFIELD_CT = "BRIDGEPORTANDFAIRFIELD_CT",
  HARTFORD_CT = "HARTFORD_CT",
  NEWBRITAIN_CT = "NEWBRITAIN_CT",
  NEWHAVEN_CT = "NEWHAVEN_CT",
  STAMFORDDARIENANDNEWCANAAN_CT = "STAMFORDDARIENANDNEWCANAAN_CT",
  WATERBURY_CT = "WATERBURY_CT",
  JACKSONVILLE_FL = "JACKSONVILLE_FL",
  MIAMI_FL = "MIAMI_FL",
  STPETERSBURG_FL = "STPETERSBURG_FL",
  TAMPA_FL = "TAMPA_FL",
  ATLANTA_GA = "ATLANTA_GA",
  AUGUSTA_GA = "AUGUSTA_GA",
  COLUMBUS_GA = "COLUMBUS_GA",
  MACON_GA = "MACON_GA",
  SAVANNAH_GA = "SAVANNAH_GA",
  CEDARRAPIDS_IA = "CEDARRAPIDS_IA",
  DAVENPORT_IA = "DAVENPORT_IA",
  DESMOINES_IA = "DESMOINES_IA",
  DUBUQUE_IA = "DUBUQUE_IA",
  SIOUXCITY_IA = "SIOUXCITY_IA",
  WATERLOO_IA = "WATERLOO_IA",
  AURORA_IL = "AURORA_IL",
  CHICAGO_IL = "CHICAGO_IL",
  DECATUR_IL = "DECATUR_IL",
  EASTSTLOUIS_IL = "EASTSTLOUIS_IL",
  JOLIET_IL = "JOLIET_IL",
  ROCKFORD_IL = "ROCKFORD_IL",
  SPRINGFIELD_IL = "SPRINGFIELD_IL",
  EVANSVILLE_IN = "EVANSVILLE_IN",
  FORTWAYNE_IN = "FORTWAYNE_IN",
  INDIANAPOLIS_IN = "INDIANAPOLIS_IN",
  LAKECOGARY_IN = "LAKECOGARY_IN",
  MUNCIE_IN = "MUNCIE_IN",
  SOUTHBEND_IN = "SOUTHBEND_IN",
  TERREHAUTE_IN = "TERREHAUTE_IN",
  TOPEKA_KS = "TOPEKA_KS",
  WICHITA_KS = "WICHITA_KS",
  NEWORLEANS_LA = "NEWORLEANS_LA",
  SHREVEPORT_LA = "SHREVEPORT_LA",
  ARLINGTON_MA = "ARLINGTON_MA",
  BELMONT_MA = "BELMONT_MA",
  BOSTON_MA = "BOSTON_MA",
  BRAINTREE_MA = "BRAINTREE_MA",
  BROCKTON_MA = "BROCKTON_MA",
  BROOKLINE_MA = "BROOKLINE_MA",
  CAMBRIDGE_MA = "CAMBRIDGE_MA",
  CHELSEA_MA = "CHELSEA_MA",
  EVERETT_MA = "EVERETT_MA",
  FITCHBURG_MA = "FITCHBURG_MA",
  HAVERHILL_MA = "HAVERHILL_MA",
  HOLYOKECHICOPEE_MA = "HOLYOKECHICOPEE_MA",
  LAWRENCE_MA = "LAWRENCE_MA",
  LEXINGTON_MA = "LEXINGTON_MA",
  LOWELL_MA = "LOWELL_MA",
  LYNN_MA = "LYNN_MA",
  MALDEN_MA = "MALDEN_MA",
  MILTON_MA = "MILTON_MA",
  PITTSFIELD_MA = "PITTSFIELD_MA",
  QUINCY_MA = "QUINCY_MA",
  SALEM_MA = "SALEM_MA",
  WORCESTER_MA = "WORCESTER_MA",
  BALTIMORE_MD = "BALTIMORE_MD",
  BATTLECREEK_MI = "BATTLECREEK_MI",
  BAYCITY_MI = "BAYCITY_MI",
  DETROIT_MI = "DETROIT_MI",
  FLINT_MI = "FLINT_MI",
  GRANDRAPIDS_MI = "GRANDRAPIDS_MI",
  KALAMAZOO_MI = "KALAMAZOO_MI",
  MUSKEGON_MI = "MUSKEGON_MI",
  PONTIAC_MI = "PONTIAC_MI",
  SAGINAW_MI = "SAGINAW_MI",
  DULUTH_MN = "DULUTH_MN",
  MINNEAPOLIS_MN = "MINNEAPOLIS_MN",
  STPAUL_MN = "STPAUL_MN",
  GREATERKANSASCITY_MO = "GREATERKANSASCITY_MO",
  SPRINGFIELD_MO = "SPRINGFIELD_MO",
  STJOSEPH_MO = "STJOSEPH_MO",
  ASHEVILLE_NC = "ASHEVILLE_NC",
  CHARLOTTE_NC = "CHARLOTTE_NC",
  DURHAM_NC = "DURHAM_NC",
  GREENSBORO_NC = "GREENSBORO_NC",
  WINSTONSALEM_NC = "WINSTONSALEM_NC",
  MANCHESTER_NH = "MANCHESTER_NH",
  ATLANTICCITY_NJ = "ATLANTICCITY_NJ",
  BERGENCO_NJ = "BERGENCO_NJ",
  CAMDEN_NJ = "CAMDEN_NJ",
  ESSEXCO_NJ = "ESSEXCO_NJ",
  HUDSONCO_NJ = "HUDSONCO_NJ",
  PASSAICCOUNTY_NJ = "PASSAICCOUNTY_NJ",
  PERTHAMBOY_NJ = "PERTHAMBOY_NJ",
  TRENTON_NJ = "TRENTON_NJ",
  UNIONCO_NJ = "UNIONCO_NJ",
  ALBANY_NY = "ALBANY_NY",
  BINGHAMTONJOHNSONCITY_NY = "BINGHAMTONJOHNSONCITY_NY",
  BRONX_NY = "BRONX_NY",
  BROOKLYN_NY = "BROOKLYN_NY",
  BUFFALO_NY = "BUFFALO_NY",
  ELMIRA_NY = "ELMIRA_NY",
  JAMESTOWN_NY = "JAMESTOWN_NY",
  LOWERWESTCHESTERCO_NY = "LOWERWESTCHESTERCO_NY",
  MANHATTAN_NY = "MANHATTAN_NY",
  NIAGARAFALLS_NY = "NIAGARAFALLS_NY",
  POUGHKEEPSIE_NY = "POUGHKEEPSIE_NY",
  QUEENS_NY = "QUEENS_NY",
  ROCHESTER_NY = "ROCHESTER_NY",
  SCHENECTADY_NY = "SCHENECTADY_NY",
  STATENISLAND_NY = "STATENISLAND_NY",
  SYRACUSE_NY = "SYRACUSE_NY",
  TROY_NY = "TROY_NY",
  UTICA_NY = "UTICA_NY",
  AKRON_OH = "AKRON_OH",
  CANTON_OH = "CANTON_OH",
  CLEVELAND_OH = "CLEVELAND_OH",
  DAYTON_OH = "DAYTON_OH",
  HAMILTON_OH = "HAMILTON_OH",
  LIMA_OH = "LIMA_OH",
  LORAIN_OH = "LORAIN_OH",
  PORTSMOUTH_OH = "PORTSMOUTH_OH",
  SPRINGFIELD_OH = "SPRINGFIELD_OH",
  TOLEDO_OH = "TOLEDO_OH",
  WARREN_OH = "WARREN_OH",
  YOUNGSTOWN_OH = "YOUNGSTOWN_OH",
  PORTLAND_OR = "PORTLAND_OR",
  ALTOONA_PA = "ALTOONA_PA",
  ERIE_PA = "ERIE_PA",
  JOHNSTOWN_PA = "JOHNSTOWN_PA",
  MCKEESPORT_PA = "MCKEESPORT_PA",
  NEWCASTLE_PA = "NEWCASTLE_PA",
  PHILADELPHIA_PA = "PHILADELPHIA_PA",
  PITTSBURGH_PA = "PITTSBURGH_PA",
  YORK_PA = "YORK_PA",
  WOONSOCKET_RI = "WOONSOCKET_RI",
  COLUMBIA_SC = "COLUMBIA_SC",
  CHATTANOOGA_TN = "CHATTANOOGA_TN",
  KNOXVILLE_TN = "KNOXVILLE_TN",
  DALLAS_TX = "DALLAS_TX",
  ELPASO_TX = "ELPASO_TX",
  OGDEN_UT = "OGDEN_UT",
  SALTLAKECITY_UT = "SALTLAKECITY_UT",
  LYNCHBURG_VA = "LYNCHBURG_VA",
  NORFOLK_VA = "NORFOLK_VA",
  RICHMOND_VA = "RICHMOND_VA",
  ROANOKE_VA = "ROANOKE_VA",
  SEATTLE_WA = "SEATTLE_WA",
  SPOKANE_WA = "SPOKANE_WA",
  TACOMA_WA = "TACOMA_WA",
  KENOSHA_WI = "KENOSHA_WI",
  MILWAUKEECO_WI = "MILWAUKEECO_WI",
  OSHKOSH_WI = "OSHKOSH_WI",
  RACINE_WI = "RACINE_WI",
  CHARLESTON_WV = "CHARLESTON_WV",
  HUNTINGTON_WV = "HUNTINGTON_WV",
  WHEELING_WV = "WHEELING_WV",
}

/**
 * defining as a type enforces that all of the Regions above are defined in regionData below
 */
export type Region = {
  enabled?: boolean
  label: string
  region: RegionEnum
  location: LngLat
  geoJson: string
  zoom?: number
}

type RegionData = {
  [key in keyof typeof RegionEnum]: Region
}

export const regionData: RegionData = {
  [RegionEnum.BALTIMORE_MD]: {
    enabled: true,
    label: "Baltimore, MD",
    region: RegionEnum.BALTIMORE_MD,
    location: { lng: -76.61, lat: 39.27 },
    geoJson: "MDBaltimore1937.json",
    zoom: 12,
  },
  [RegionEnum.CLEVELAND_OH]: {
    enabled: true,
    label: "Cleveland",
    region: RegionEnum.CLEVELAND_OH,
    location: { lng: -81.70, lat: 41.44 },
    geoJson: "OHCleveland1939.json",
    zoom: 11,
  },
  [RegionEnum.DETROIT_MI]: {
    label: "Detroit",
    region: RegionEnum.DETROIT_MI,
    location: { lng: -83.09, lat: 42.36 },
    geoJson: "MIDetroit1939.json",
    zoom: 11,
  },
  [RegionEnum.BIRMINGHAM_AL]: {
    label: "Birmingham",
    region: RegionEnum.BIRMINGHAM_AL,
    location: { lng: 0, lat: 0 },
    geoJson: "ALBirmingham1937.json"
  },
  [RegionEnum.MOBILE_AL]: {
    label: "Mobile",
    region: RegionEnum.MOBILE_AL,
    location: { lng: 0, lat: 0 },
    geoJson: "ALMobile1937.json"
  },
  [RegionEnum.MONTGOMERY_AL]: {
    label: "Montgomery",
    region: RegionEnum.MONTGOMERY_AL,
    location: { lng: 0, lat: 0 },
    geoJson: "ALMontgomery1937.json"
  },
  [RegionEnum.LITTLEROCK_AR]: {
    label: "Littlerock",
    region: RegionEnum.LITTLEROCK_AR,
    location: { lng: 0, lat: 0 },
    geoJson: "ARLittleRock19XX.json"
  },
  [RegionEnum.PHOENIX_AZ]: {
    label: "Phoenix",
    region: RegionEnum.PHOENIX_AZ,
    location: { lng: 0, lat: 0 },
    geoJson: "AZPhoenix19XX.json"
  },
  [RegionEnum.FRESNO_CA]: {
    label: "Fresno",
    region: RegionEnum.FRESNO_CA,
    location: { lng: 0, lat: 0 },
    geoJson: "CAFresno1936.json"
  },
  [RegionEnum.LOSANGELES_CA]: {
    label: "Losangeles",
    region: RegionEnum.LOSANGELES_CA,
    location: { lng: 0, lat: 0 },
    geoJson: "CALosAngeles1939.json"
  },
  [RegionEnum.OAKLAND_CA]: {
    label: "Oakland",
    region: RegionEnum.OAKLAND_CA,
    location: { lng: 0, lat: 0 },
    geoJson: "CAOakland1937.json"
  },
  [RegionEnum.SACRAMENTO_CA]: {
    label: "Sacramento",
    region: RegionEnum.SACRAMENTO_CA,
    location: { lng: 0, lat: 0 },
    geoJson: "CASacramento1937.json"
  },
  [RegionEnum.SANDIEGO_CA]: {
    label: "Sandiego",
    region: RegionEnum.SANDIEGO_CA,
    location: { lng: 0, lat: 0 },
    geoJson: "CASanDiego1938.json"
  },
  [RegionEnum.SANFRANCISCO_CA]: {
    label: "Sanfrancisco",
    region: RegionEnum.SANFRANCISCO_CA,
    location: { lng: 0, lat: 0 },
    geoJson: "CASanFrancisco1937.json"
  },
  [RegionEnum.SANJOSE_CA]: {
    label: "Sanjose",
    region: RegionEnum.SANJOSE_CA,
    location: { lng: 0, lat: 0 },
    geoJson: "CASanJose1937.json"
  },
  [RegionEnum.STOCKTON_CA]: {
    label: "Stockton",
    region: RegionEnum.STOCKTON_CA,
    location: { lng: 0, lat: 0 },
    geoJson: "CAStockton1938.json"
  },
  [RegionEnum.DENVER_CO]: {
    label: "Denver",
    region: RegionEnum.DENVER_CO,
    location: { lng: 0, lat: 0 },
    geoJson: "CODenver1938.json"
  },
  [RegionEnum.PUEBLO_CO]: {
    label: "Pueblo",
    region: RegionEnum.PUEBLO_CO,
    location: { lng: 0, lat: 0 },
    geoJson: "COPueblo19XX.json"
  },
  [RegionEnum.BRIDGEPORTANDFAIRFIELD_CT]: {
    label: "Bridgeportandfairfield",
    region: RegionEnum.BRIDGEPORTANDFAIRFIELD_CT,
    location: { lng: 0, lat: 0 },
    geoJson: "CTBridgeportandFairfield1937.json"
  },
  [RegionEnum.HARTFORD_CT]: {
    label: "Hartford",
    region: RegionEnum.HARTFORD_CT,
    location: { lng: 0, lat: 0 },
    geoJson: "CTHartford19XX.json"
  },
  [RegionEnum.NEWBRITAIN_CT]: {
    label: "Newbritain",
    region: RegionEnum.NEWBRITAIN_CT,
    location: { lng: 0, lat: 0 },
    geoJson: "CTNewBritain1937.json"
  },
  [RegionEnum.NEWHAVEN_CT]: {
    label: "Newhaven",
    region: RegionEnum.NEWHAVEN_CT,
    location: { lng: 0, lat: 0 },
    geoJson: "CTNewHaven1937.json"
  },
  [RegionEnum.STAMFORDDARIENANDNEWCANAAN_CT]: {
    label: "Stamforddarienandnewcanaan",
    region: RegionEnum.STAMFORDDARIENANDNEWCANAAN_CT,
    location: { lng: 0, lat: 0 },
    geoJson: "CTStamfordDarienandNewCanaan1937.json"
  },
  [RegionEnum.WATERBURY_CT]: {
    label: "Waterbury",
    region: RegionEnum.WATERBURY_CT,
    location: { lng: 0, lat: 0 },
    geoJson: "CTWaterbury19XX.json"
  },
  [RegionEnum.JACKSONVILLE_FL]: {
    label: "Jacksonville",
    region: RegionEnum.JACKSONVILLE_FL,
    location: { lng: 0, lat: 0 },
    geoJson: "FLJacksonville1937.json"
  },
  [RegionEnum.MIAMI_FL]: {
    label: "Miami",
    region: RegionEnum.MIAMI_FL,
    location: { lng: 0, lat: 0 },
    geoJson: "FLMiami1937.json"
  },
  [RegionEnum.STPETERSBURG_FL]: {
    label: "Stpetersburg",
    region: RegionEnum.STPETERSBURG_FL,
    location: { lng: 0, lat: 0 },
    geoJson: "FLStPetersburg1937.json"
  },
  [RegionEnum.TAMPA_FL]: {
    label: "Tampa",
    region: RegionEnum.TAMPA_FL,
    location: { lng: 0, lat: 0 },
    geoJson: "FLTampa1936.json"
  },
  [RegionEnum.ATLANTA_GA]: {
    label: "Atlanta",
    region: RegionEnum.ATLANTA_GA,
    location: { lng: 0, lat: 0 },
    geoJson: "GAAtlanta1938.json"
  },
  [RegionEnum.AUGUSTA_GA]: {
    label: "Augusta",
    region: RegionEnum.AUGUSTA_GA,
    location: { lng: 0, lat: 0 },
    geoJson: "GAAugusta1937.json"
  },
  [RegionEnum.COLUMBUS_GA]: {
    label: "Columbus",
    region: RegionEnum.COLUMBUS_GA,
    location: { lng: 0, lat: 0 },
    geoJson: "GAColumbus1937.json"
  },
  [RegionEnum.MACON_GA]: {
    label: "Macon",
    region: RegionEnum.MACON_GA,
    location: { lng: 0, lat: 0 },
    geoJson: "GAMacon1937.json"
  },
  [RegionEnum.SAVANNAH_GA]: {
    label: "Savannah",
    region: RegionEnum.SAVANNAH_GA,
    location: { lng: 0, lat: 0 },
    geoJson: "GASavannah19XX.json"
  },
  [RegionEnum.CEDARRAPIDS_IA]: {
    label: "Cedarrapids",
    region: RegionEnum.CEDARRAPIDS_IA,
    location: { lng: 0, lat: 0 },
    geoJson: "IACedarRapids19XX.json"
  },
  [RegionEnum.DAVENPORT_IA]: {
    label: "Davenport",
    region: RegionEnum.DAVENPORT_IA,
    location: { lng: 0, lat: 0 },
    geoJson: "IADavenport19XX.json"
  },
  [RegionEnum.DESMOINES_IA]: {
    label: "Desmoines",
    region: RegionEnum.DESMOINES_IA,
    location: { lng: 0, lat: 0 },
    geoJson: "IADesMoines19XX.json"
  },
  [RegionEnum.DUBUQUE_IA]: {
    label: "Dubuque",
    region: RegionEnum.DUBUQUE_IA,
    location: { lng: 0, lat: 0 },
    geoJson: "IADubuque19XX.json"
  },
  [RegionEnum.SIOUXCITY_IA]: {
    label: "Siouxcity",
    region: RegionEnum.SIOUXCITY_IA,
    location: { lng: 0, lat: 0 },
    geoJson: "IASiouxCity19XX.json"
  },
  [RegionEnum.WATERLOO_IA]: {
    label: "Waterloo",
    region: RegionEnum.WATERLOO_IA,
    location: { lng: 0, lat: 0 },
    geoJson: "IAWaterloo19XX.json"
  },
  [RegionEnum.AURORA_IL]: {
    label: "Aurora",
    region: RegionEnum.AURORA_IL,
    location: { lng: 0, lat: 0 },
    geoJson: "ILAurora1938.json"
  },
  [RegionEnum.CHICAGO_IL]: {
    label: "Chicago",
    region: RegionEnum.CHICAGO_IL,
    location: { lng: 0, lat: 0 },
    geoJson: "ILChicago1940.json"
  },
  [RegionEnum.DECATUR_IL]: {
    label: "Decatur",
    region: RegionEnum.DECATUR_IL,
    location: { lng: 0, lat: 0 },
    geoJson: "ILDecatur1937.json"
  },
  [RegionEnum.EASTSTLOUIS_IL]: {
    label: "Eaststlouis",
    region: RegionEnum.EASTSTLOUIS_IL,
    location: { lng: 0, lat: 0 },
    geoJson: "ILEastStLouis1940.json"
  },
  [RegionEnum.JOLIET_IL]: {
    label: "Joliet",
    region: RegionEnum.JOLIET_IL,
    location: { lng: 0, lat: 0 },
    geoJson: "ILJoliet1937.json"
  },
  [RegionEnum.ROCKFORD_IL]: {
    label: "Rockford",
    region: RegionEnum.ROCKFORD_IL,
    location: { lng: 0, lat: 0 },
    geoJson: "ILRockford1937.json"
  },
  [RegionEnum.SPRINGFIELD_IL]: {
    label: "Springfield",
    region: RegionEnum.SPRINGFIELD_IL,
    location: { lng: 0, lat: 0 },
    geoJson: "ILSpringfield1937.json"
  },
  [RegionEnum.EVANSVILLE_IN]: {
    label: "Evansville",
    region: RegionEnum.EVANSVILLE_IN,
    location: { lng: 0, lat: 0 },
    geoJson: "INEvansville1937.json"
  },
  [RegionEnum.FORTWAYNE_IN]: {
    label: "Fortwayne",
    region: RegionEnum.FORTWAYNE_IN,
    location: { lng: 0, lat: 0 },
    geoJson: "INFortWayne1937.json"
  },
  [RegionEnum.INDIANAPOLIS_IN]: {
    label: "Indianapolis",
    region: RegionEnum.INDIANAPOLIS_IN,
    location: { lng: 0, lat: 0 },
    geoJson: "INIndianapolis1937.json"
  },
  [RegionEnum.LAKECOGARY_IN]: {
    label: "Lakecogary",
    region: RegionEnum.LAKECOGARY_IN,
    location: { lng: 0, lat: 0 },
    geoJson: "INLakeCoGary1937.json"
  },
  [RegionEnum.MUNCIE_IN]: {
    label: "Muncie",
    region: RegionEnum.MUNCIE_IN,
    location: { lng: 0, lat: 0 },
    geoJson: "INMuncie1937.json"
  },
  [RegionEnum.SOUTHBEND_IN]: {
    label: "Southbend",
    region: RegionEnum.SOUTHBEND_IN,
    location: { lng: 0, lat: 0 },
    geoJson: "INSouthBend1937.json"
  },
  [RegionEnum.TERREHAUTE_IN]: {
    label: "Terrehaute",
    region: RegionEnum.TERREHAUTE_IN,
    location: { lng: 0, lat: 0 },
    geoJson: "INTerreHaute1937.json"
  },
  [RegionEnum.TOPEKA_KS]: {
    label: "Topeka",
    region: RegionEnum.TOPEKA_KS,
    location: { lng: 0, lat: 0 },
    geoJson: "KSTopeka19XX.json"
  },
  [RegionEnum.WICHITA_KS]: {
    label: "Wichita",
    region: RegionEnum.WICHITA_KS,
    location: { lng: 0, lat: 0 },
    geoJson: "KSWichita1937.json"
  },
  [RegionEnum.NEWORLEANS_LA]: {
    label: "Neworleans",
    region: RegionEnum.NEWORLEANS_LA,
    location: { lng: 0, lat: 0 },
    geoJson: "LANewOrleans1939.json"
  },
  [RegionEnum.SHREVEPORT_LA]: {
    label: "Shreveport",
    region: RegionEnum.SHREVEPORT_LA,
    location: { lng: 0, lat: 0 },
    geoJson: "LAShreveport19XX.json"
  },
  [RegionEnum.ARLINGTON_MA]: {
    label: "Arlington",
    region: RegionEnum.ARLINGTON_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MAArlington1939.json"
  },
  [RegionEnum.BELMONT_MA]: {
    label: "Belmont",
    region: RegionEnum.BELMONT_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MABelmont1939.json"
  },
  [RegionEnum.BOSTON_MA]: {
    label: "Boston",
    region: RegionEnum.BOSTON_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MABoston1938.json"
  },
  [RegionEnum.BRAINTREE_MA]: {
    label: "Braintree",
    region: RegionEnum.BRAINTREE_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MABraintree1939.json"
  },
  [RegionEnum.BROCKTON_MA]: {
    label: "Brockton",
    region: RegionEnum.BROCKTON_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MABrockton1937.json"
  },
  [RegionEnum.BROOKLINE_MA]: {
    label: "Brookline",
    region: RegionEnum.BROOKLINE_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MABrookline1939.json"
  },
  [RegionEnum.CAMBRIDGE_MA]: {
    label: "Cambridge",
    region: RegionEnum.CAMBRIDGE_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MACambridge1939.json"
  },
  [RegionEnum.CHELSEA_MA]: {
    label: "Chelsea",
    region: RegionEnum.CHELSEA_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MAChelsea1939.json"
  },
  [RegionEnum.EVERETT_MA]: {
    label: "Everett",
    region: RegionEnum.EVERETT_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MAEverett19XX.json"
  },
  [RegionEnum.FITCHBURG_MA]: {
    label: "Fitchburg",
    region: RegionEnum.FITCHBURG_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MAFitchburg1936.json"
  },
  [RegionEnum.HAVERHILL_MA]: {
    label: "Haverhill",
    region: RegionEnum.HAVERHILL_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MAHaverhill1937.json"
  },
  [RegionEnum.HOLYOKECHICOPEE_MA]: {
    label: "Holyokechicopee",
    region: RegionEnum.HOLYOKECHICOPEE_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MAHolyokeChicopee1937.json"
  },
  [RegionEnum.LAWRENCE_MA]: {
    label: "Lawrence",
    region: RegionEnum.LAWRENCE_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MALawrence1937.json"
  },
  [RegionEnum.LEXINGTON_MA]: {
    label: "Lexington",
    region: RegionEnum.LEXINGTON_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MALexington19XX.json"
  },
  [RegionEnum.LOWELL_MA]: {
    label: "Lowell",
    region: RegionEnum.LOWELL_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MALowell1936.json"
  },
  [RegionEnum.LYNN_MA]: {
    label: "Lynn",
    region: RegionEnum.LYNN_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MALynn1936.json"
  },
  [RegionEnum.MALDEN_MA]: {
    label: "Malden",
    region: RegionEnum.MALDEN_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MAMalden19XX.json"
  },
  [RegionEnum.MILTON_MA]: {
    label: "Milton",
    region: RegionEnum.MILTON_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MAMilton1939.json"
  },
  [RegionEnum.PITTSFIELD_MA]: {
    label: "Pittsfield",
    region: RegionEnum.PITTSFIELD_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MAPittsfield1936.json"
  },
  [RegionEnum.QUINCY_MA]: {
    label: "Quincy",
    region: RegionEnum.QUINCY_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MAQuincy1939.json"
  },
  [RegionEnum.SALEM_MA]: {
    label: "Salem",
    region: RegionEnum.SALEM_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MASalem1936.json"
  },
  [RegionEnum.WORCESTER_MA]: {
    label: "Worcester",
    region: RegionEnum.WORCESTER_MA,
    location: { lng: 0, lat: 0 },
    geoJson: "MAWorcester1936.json"
  },
  [RegionEnum.BATTLECREEK_MI]: {
    label: "Battlecreek",
    region: RegionEnum.BATTLECREEK_MI,
    location: { lng: 0, lat: 0 },
    geoJson: "MIBattleCreek1937.json"
  },
  [RegionEnum.BAYCITY_MI]: {
    label: "Baycity",
    region: RegionEnum.BAYCITY_MI,
    location: { lng: 0, lat: 0 },
    geoJson: "MIBayCity1937.json",
  },
  [RegionEnum.FLINT_MI]: {
    label: "Flint",
    region: RegionEnum.FLINT_MI,
    location: { lng: 0, lat: 0 },
    geoJson: "MIFlint1937.json"
  },
  [RegionEnum.GRANDRAPIDS_MI]: {
    label: "Grandrapids",
    region: RegionEnum.GRANDRAPIDS_MI,
    location: { lng: 0, lat: 0 },
    geoJson: "MIGrandRapids1937.json"
  },
  [RegionEnum.KALAMAZOO_MI]: {
    label: "Kalamazoo",
    region: RegionEnum.KALAMAZOO_MI,
    location: { lng: 0, lat: 0 },
    geoJson: "MIKalamazoo1937.json"
  },
  [RegionEnum.MUSKEGON_MI]: {
    label: "Muskegon",
    region: RegionEnum.MUSKEGON_MI,
    location: { lng: 0, lat: 0 },
    geoJson: "MIMuskegon1937.json"
  },
  [RegionEnum.PONTIAC_MI]: {
    label: "Pontiac",
    region: RegionEnum.PONTIAC_MI,
    location: { lng: 0, lat: 0 },
    geoJson: "MIPontiac1937.json"
  },
  [RegionEnum.SAGINAW_MI]: {
    label: "Saginaw",
    region: RegionEnum.SAGINAW_MI,
    location: { lng: 0, lat: 0 },
    geoJson: "MISaginaw1937.json"
  },
  [RegionEnum.DULUTH_MN]: {
    label: "Duluth",
    region: RegionEnum.DULUTH_MN,
    location: { lng: 0, lat: 0 },
    geoJson: "MNDuluth1936.json"
  },
  [RegionEnum.MINNEAPOLIS_MN]: {
    label: "Minneapolis",
    region: RegionEnum.MINNEAPOLIS_MN,
    location: { lng: 0, lat: 0 },
    geoJson: "MNMinneapolis1937.json"
  },
  [RegionEnum.STPAUL_MN]: {
    label: "Stpaul",
    region: RegionEnum.STPAUL_MN,
    location: { lng: 0, lat: 0 },
    geoJson: "MNStPaul19XX.json"
  },
  [RegionEnum.GREATERKANSASCITY_MO]: {
    label: "Greaterkansascity",
    region: RegionEnum.GREATERKANSASCITY_MO,
    location: { lng: 0, lat: 0 },
    geoJson: "MOGreaterKansasCity1939.json"
  },
  [RegionEnum.SPRINGFIELD_MO]: {
    label: "Springfield",
    region: RegionEnum.SPRINGFIELD_MO,
    location: { lng: 0, lat: 0 },
    geoJson: "MOSpringfield1937.json"
  },
  [RegionEnum.STJOSEPH_MO]: {
    label: "Stjoseph",
    region: RegionEnum.STJOSEPH_MO,
    location: { lng: 0, lat: 0 },
    geoJson: "MOStJoseph1937.json"
  },
  [RegionEnum.ASHEVILLE_NC]: {
    label: "Asheville",
    region: RegionEnum.ASHEVILLE_NC,
    location: { lng: 0, lat: 0 },
    geoJson: "NCAsheville1937.json"
  },
  [RegionEnum.CHARLOTTE_NC]: {
    label: "Charlotte",
    region: RegionEnum.CHARLOTTE_NC,
    location: { lng: 0, lat: 0 },
    geoJson: "NCCharlotte1935.json"
  },
  [RegionEnum.DURHAM_NC]: {
    label: "Durham",
    region: RegionEnum.DURHAM_NC,
    location: { lng: 0, lat: 0 },
    geoJson: "NCDurham1937.json"
  },
  [RegionEnum.GREENSBORO_NC]: {
    label: "Greensboro",
    region: RegionEnum.GREENSBORO_NC,
    location: { lng: 0, lat: 0 },
    geoJson: "NCGreensboro1936.json"
  },
  [RegionEnum.WINSTONSALEM_NC]: {
    label: "Winstonsalem",
    region: RegionEnum.WINSTONSALEM_NC,
    location: { lng: 0, lat: 0 },
    geoJson: "NCWinstonSalem1937.json"
  },
  [RegionEnum.MANCHESTER_NH]: {
    label: "Manchester",
    region: RegionEnum.MANCHESTER_NH,
    location: { lng: 0, lat: 0 },
    geoJson: "NHManchester1937.json"
  },
  [RegionEnum.ATLANTICCITY_NJ]: {
    label: "Atlanticcity",
    region: RegionEnum.ATLANTICCITY_NJ,
    location: { lng: 0, lat: 0 },
    geoJson: "NJAtlanticCity1940.json"
  },
  [RegionEnum.BERGENCO_NJ]: {
    label: "Bergenco",
    region: RegionEnum.BERGENCO_NJ,
    location: { lng: 0, lat: 0 },
    geoJson: "NJBergenCo1939.json"
  },
  [RegionEnum.CAMDEN_NJ]: {
    label: "Camden",
    region: RegionEnum.CAMDEN_NJ,
    location: { lng: 0, lat: 0 },
    geoJson: "NJCamden1937.json"
  },
  [RegionEnum.ESSEXCO_NJ]: {
    label: "Essexco",
    region: RegionEnum.ESSEXCO_NJ,
    location: { lng: 0, lat: 0 },
    geoJson: "NJEssexCo1939.json"
  },
  [RegionEnum.HUDSONCO_NJ]: {
    label: "Hudsonco",
    region: RegionEnum.HUDSONCO_NJ,
    location: { lng: 0, lat: 0 },
    geoJson: "NJHudsonCo1939.json"
  },
  [RegionEnum.PASSAICCOUNTY_NJ]: {
    label: "Passaiccounty",
    region: RegionEnum.PASSAICCOUNTY_NJ,
    location: { lng: 0, lat: 0 },
    geoJson: "NJPassaicCounty1939.json"
  },
  [RegionEnum.PERTHAMBOY_NJ]: {
    label: "Perthamboy",
    region: RegionEnum.PERTHAMBOY_NJ,
    location: { lng: 0, lat: 0 },
    geoJson: "NJPerthAmboy1937.json"
  },
  [RegionEnum.TRENTON_NJ]: {
    label: "Trenton",
    region: RegionEnum.TRENTON_NJ,
    location: { lng: 0, lat: 0 },
    geoJson: "NJTrenton1937.json"
  },
  [RegionEnum.UNIONCO_NJ]: {
    label: "Unionco",
    region: RegionEnum.UNIONCO_NJ,
    location: { lng: 0, lat: 0 },
    geoJson: "NJUnionCo1939.json"
  },
  [RegionEnum.ALBANY_NY]: {
    enabled: true,
    zoom: 12,
    label: "Albany",
    region: RegionEnum.ALBANY_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYAlbany1938.json"
  },
  [RegionEnum.BINGHAMTONJOHNSONCITY_NY]: {
    enabled: true,
    zoom: 12,
    label: "Binghamtonjohnsoncity",
    region: RegionEnum.BINGHAMTONJOHNSONCITY_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYBinghamtonJohnsonCity1937.json"
  },
  [RegionEnum.BRONX_NY]: {
    enabled: true,
    zoom: 12,
    label: "Bronx",
    region: RegionEnum.BRONX_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYBronx1938.json"
  },
  [RegionEnum.BROOKLYN_NY]: {
    enabled: true,
    zoom: 12,
    label: "Brooklyn",
    region: RegionEnum.BROOKLYN_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYBrooklyn1938.json"
  },
  [RegionEnum.BUFFALO_NY]: {
    enabled: true,
    zoom: 12,
    label: "Buffalo",
    region: RegionEnum.BUFFALO_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYBuffalo1937.json"
  },
  [RegionEnum.ELMIRA_NY]: {
    enabled: true,
    zoom: 12,
    label: "Elmira",
    region: RegionEnum.ELMIRA_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYElmira1937.json"
  },
  [RegionEnum.JAMESTOWN_NY]: {
    enabled: true,
    zoom: 12,
    label: "Jamestown",
    region: RegionEnum.JAMESTOWN_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYJamestown1937.json"
  },
  [RegionEnum.LOWERWESTCHESTERCO_NY]: {
    enabled: true,
    zoom: 12,
    label: "Lowerwestchesterco",
    region: RegionEnum.LOWERWESTCHESTERCO_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYLowerWestchesterCo1940.json"
  },
  [RegionEnum.MANHATTAN_NY]: {
    enabled: true,
    zoom: 12,
    label: "Manhattan",
    region: RegionEnum.MANHATTAN_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYManhattan1937.json"
  },
  [RegionEnum.NIAGARAFALLS_NY]: {
    enabled: true,
    zoom: 12,
    label: "Niagarafalls",
    region: RegionEnum.NIAGARAFALLS_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYNiagaraFalls1937.json"
  },
  [RegionEnum.POUGHKEEPSIE_NY]: {
    enabled: true,
    zoom: 12,
    label: "Poughkeepsie",
    region: RegionEnum.POUGHKEEPSIE_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYPoughkeepsie1938.json"
  },
  [RegionEnum.QUEENS_NY]: {
    enabled: true,
    zoom: 12,
    label: "Queens",
    region: RegionEnum.QUEENS_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYQueens1938.json"
  },
  [RegionEnum.ROCHESTER_NY]: {
    enabled: true,
    zoom: 12,
    label: "Rochester",
    region: RegionEnum.ROCHESTER_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYRochester1935.json"
  },
  [RegionEnum.SCHENECTADY_NY]: {
    enabled: true,
    zoom: 12,
    label: "Schenectady",
    region: RegionEnum.SCHENECTADY_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYSchenectady1938.json"
  },
  [RegionEnum.STATENISLAND_NY]: {
    enabled: true,
    zoom: 12,
    label: "Statenisland",
    region: RegionEnum.STATENISLAND_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYStatenIsland1940.json"
  },
  [RegionEnum.SYRACUSE_NY]: {
    enabled: true,
    zoom: 12,
    label: "Syracuse",
    region: RegionEnum.SYRACUSE_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYSyracuse1937.json"
  },
  [RegionEnum.TROY_NY]: {
    enabled: true,
    zoom: 12,
    label: "Troy",
    region: RegionEnum.TROY_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYTroy1937.json"
  },
  [RegionEnum.UTICA_NY]: {
    enabled: true,
    zoom: 12,
    label: "Utica",
    region: RegionEnum.UTICA_NY,
    location: { lng: 0, lat: 0 },
    geoJson: "NYUtica1936.json"
  },
  [RegionEnum.AKRON_OH]: {
    label: "Akron",
    region: RegionEnum.AKRON_OH,
    location: { lng: 0, lat: 0 },
    geoJson: "OHAkron1939.json"
  },
  [RegionEnum.CANTON_OH]: {
    label: "Canton",
    region: RegionEnum.CANTON_OH,
    location: { lng: 0, lat: 0 },
    geoJson: "OHCanton1937.json"
  },
  [RegionEnum.DAYTON_OH]: {
    label: "Dayton",
    region: RegionEnum.DAYTON_OH,
    location: { lng: 0, lat: 0 },
    geoJson: "OHDayton1937.json"
  },
  [RegionEnum.HAMILTON_OH]: {
    label: "Hamilton",
    region: RegionEnum.HAMILTON_OH,
    location: { lng: 0, lat: 0 },
    geoJson: "OHHamilton1938.json"
  },
  [RegionEnum.LIMA_OH]: {
    label: "Lima",
    region: RegionEnum.LIMA_OH,
    location: { lng: 0, lat: 0 },
    geoJson: "OHLima1937.json"
  },
  [RegionEnum.LORAIN_OH]: {
    label: "Lorain",
    region: RegionEnum.LORAIN_OH,
    location: { lng: 0, lat: 0 },
    geoJson: "OHLorain1937.json"
  },
  [RegionEnum.PORTSMOUTH_OH]: {
    label: "Portsmouth",
    region: RegionEnum.PORTSMOUTH_OH,
    location: { lng: 0, lat: 0 },
    geoJson: "OHPortsmouth1938.json"
  },
  [RegionEnum.SPRINGFIELD_OH]: {
    label: "Springfield",
    region: RegionEnum.SPRINGFIELD_OH,
    location: { lng: 0, lat: 0 },
    geoJson: "OHSpringfield1938.json"
  },
  [RegionEnum.TOLEDO_OH]: {
    label: "Toledo",
    region: RegionEnum.TOLEDO_OH,
    location: { lng: 0, lat: 0 },
    geoJson: "OHToledo1938.json"
  },
  [RegionEnum.WARREN_OH]: {
    label: "Warren",
    region: RegionEnum.WARREN_OH,
    location: { lng: 0, lat: 0 },
    geoJson: "OHWarren1937.json"
  },
  [RegionEnum.YOUNGSTOWN_OH]: {
    label: "Youngstown",
    region: RegionEnum.YOUNGSTOWN_OH,
    location: { lng: 0, lat: 0 },
    geoJson: "OHYoungstown1938.json"
  },
  [RegionEnum.PORTLAND_OR]: {
    label: "Portland",
    region: RegionEnum.PORTLAND_OR,
    location: { lng: 0, lat: 0 },
    geoJson: "ORPortland1937.json"
  },
  [RegionEnum.ALTOONA_PA]: {
    label: "Altoona",
    region: RegionEnum.ALTOONA_PA,
    location: { lng: 0, lat: 0 },
    geoJson: "PAAltoona1937.json"
  },
  [RegionEnum.ERIE_PA]: { label: "Erie", region: RegionEnum.ERIE_PA, location: { lng: 0, lat: 0 }, geoJson: "PAErie1937.json" },
  [RegionEnum.JOHNSTOWN_PA]: {
    label: "Johnstown",
    region: RegionEnum.JOHNSTOWN_PA,
    location: { lng: 0, lat: 0 },
    geoJson: "PAJohnstown1937.json"
  },
  [RegionEnum.MCKEESPORT_PA]: {
    label: "Mckeesport",
    region: RegionEnum.MCKEESPORT_PA,
    location: { lng: 0, lat: 0 },
    geoJson: "PAMcKeesport1937.json"
  },
  [RegionEnum.NEWCASTLE_PA]: {
    label: "Newcastle",
    region: RegionEnum.NEWCASTLE_PA,
    location: { lng: 0, lat: 0 },
    geoJson: "PANewCastle1937.json"
  },
  [RegionEnum.PHILADELPHIA_PA]: {
    label: "Philadelphia",
    region: RegionEnum.PHILADELPHIA_PA,
    location: { lng: 0, lat: 0 },
    geoJson: "PAPhiladelphia1937.json"
  },
  [RegionEnum.PITTSBURGH_PA]: {
    label: "Pittsburgh",
    region: RegionEnum.PITTSBURGH_PA,
    location: { lng: 0, lat: 0 },
    geoJson: "PAPittsburgh1937.json"
  },
  [RegionEnum.YORK_PA]: { label: "York", region: RegionEnum.YORK_PA, location: { lng: 0, lat: 0 }, geoJson: "PAYork19XX.json" },
  [RegionEnum.WOONSOCKET_RI]: {
    label: "Woonsocket",
    region: RegionEnum.WOONSOCKET_RI,
    location: { lng: 0, lat: 0 },
    geoJson: "RIWoonsocket19XX.json"
  },
  [RegionEnum.COLUMBIA_SC]: {
    label: "Columbia",
    region: RegionEnum.COLUMBIA_SC,
    location: { lng: 0, lat: 0 },
    geoJson: "SCColumbia19XX.json"
  },
  [RegionEnum.CHATTANOOGA_TN]: {
    label: "Chattanooga",
    region: RegionEnum.CHATTANOOGA_TN,
    location: { lng: 0, lat: 0 },
    geoJson: "TNChattanooga1939.json"
  },
  [RegionEnum.KNOXVILLE_TN]: {
    label: "Knoxville",
    region: RegionEnum.KNOXVILLE_TN,
    location: { lng: 0, lat: 0 },
    geoJson: "TNKnoxville1939.json"
  },
  [RegionEnum.DALLAS_TX]: {
    label: "Dallas",
    region: RegionEnum.DALLAS_TX,
    location: { lng: 0, lat: 0 },
    geoJson: "TXDallas1937.json"
  },
  [RegionEnum.ELPASO_TX]: {
    label: "Elpaso",
    region: RegionEnum.ELPASO_TX,
    location: { lng: 0, lat: 0 },
    geoJson: "TXElPaso1936.json"
  },
  [RegionEnum.OGDEN_UT]: {
    label: "Ogden",
    region: RegionEnum.OGDEN_UT,
    location: { lng: 0, lat: 0 },
    geoJson: "UTOgden19XX.json"
  },
  [RegionEnum.SALTLAKECITY_UT]: {
    label: "Saltlakecity",
    region: RegionEnum.SALTLAKECITY_UT,
    location: { lng: 0, lat: 0 },
    geoJson: "UTSaltLakeCity19XX.json"
  },
  [RegionEnum.LYNCHBURG_VA]: {
    label: "Lynchburg",
    region: RegionEnum.LYNCHBURG_VA,
    location: { lng: 0, lat: 0 },
    geoJson: "VALynchburg1937.json"
  },
  [RegionEnum.NORFOLK_VA]: {
    label: "Norfolk",
    region: RegionEnum.NORFOLK_VA,
    location: { lng: 0, lat: 0 },
    geoJson: "VANorfolk1940.json"
  },
  [RegionEnum.RICHMOND_VA]: {
    label: "Richmond",
    region: RegionEnum.RICHMOND_VA,
    location: { lng: 0, lat: 0 },
    geoJson: "VARichmond1937.json"
  },
  [RegionEnum.ROANOKE_VA]: {
    label: "Roanoke",
    region: RegionEnum.ROANOKE_VA,
    location: { lng: 0, lat: 0 },
    geoJson: "VARoanoke1937.json"
  },
  [RegionEnum.SEATTLE_WA]: {
    label: "Seattle",
    region: RegionEnum.SEATTLE_WA,
    location: { lng: 0, lat: 0 },
    geoJson: "WASeattle1936.json"
  },
  [RegionEnum.SPOKANE_WA]: {
    label: "Spokane",
    region: RegionEnum.SPOKANE_WA,
    location: { lng: 0, lat: 0 },
    geoJson: "WASpokane1938.json"
  },
  [RegionEnum.TACOMA_WA]: {
    label: "Tacoma",
    region: RegionEnum.TACOMA_WA,
    location: { lng: 0, lat: 0 },
    geoJson: "WATacoma1937.json"
  },
  [RegionEnum.KENOSHA_WI]: {
    label: "Kenosha",
    region: RegionEnum.KENOSHA_WI,
    location: { lng: 0, lat: 0 },
    geoJson: "WIKenosha1937.json"
  },
  [RegionEnum.MILWAUKEECO_WI]: {
    label: "Milwaukeeco",
    region: RegionEnum.MILWAUKEECO_WI,
    location: { lng: 0, lat: 0 },
    geoJson: "WIMilwaukeeCo1937.json"
  },
  [RegionEnum.OSHKOSH_WI]: {
    label: "Oshkosh",
    region: RegionEnum.OSHKOSH_WI,
    location: { lng: 0, lat: 0 },
    geoJson: "WIOshkosh1938.json"
  },
  [RegionEnum.RACINE_WI]: {
    label: "Racine",
    region: RegionEnum.RACINE_WI,
    location: { lng: 0, lat: 0 },
    geoJson: "WIRacine1937.json"
  },
  [RegionEnum.CHARLESTON_WV]: {
    label: "Charleston",
    region: RegionEnum.CHARLESTON_WV,
    location: { lng: 0, lat: 0 },
    geoJson: "WVCharleston1938.json"
  },
  [RegionEnum.HUNTINGTON_WV]: {
    label: "Huntington",
    region: RegionEnum.HUNTINGTON_WV,
    location: { lng: 0, lat: 0 },
    geoJson: "WVHuntington19XX.json"
  },
  [RegionEnum.WHEELING_WV]: {
    label: "Wheeling",
    region: RegionEnum.WHEELING_WV,
    location: { lng: 0, lat: 0 },
    geoJson: "WVWheeling1938.json"
  },

}
